<template>
  <div id="ContasPagarNovoModal">
{{ contaPagarForm.cod_imovel }}
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
        <span class="white--text title-page body-1">Contas a Pagar</span>
          <v-btn @click="fechar()" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="wrapper pa-0">
        <div>
          <v-row class="mt-6 px-5">
            <v-col>
              <v-form ref="form" v-model="valid">
                <fieldset class="d-flex flex-column rounded mb-2" style="border: none">
                  <v-row class="mt-n5">
                    <v-col cols="12" class="px-2">
                      <!-- <pre>{{ contaPagarForm.obj_devedor_referencia }}</pre> -->
                      <v-autocomplete
                        v-model="contaPagarForm.obj_devedor_referencia"
                        :loading="loading"
                        :search-input.sync="search_devedor"
                        :items="array_devedor"
                        class="mt-n2"
                        label="Devedor *"
                        placeholder="Devedor"
                        item-text="pessoa_nome" 
                        item-value="cod_pessoa"
                        auto-select-first  
                        filled 
                        outlined 
                        dense 
                        :rules="devedorRules"
                        return-object
                        background-color="map-deep-merge white" >
                        <template #append-outer>
                          <v-icon
                            class="pointer"
                            style="cursor: pointer"
                            color="grey darken-1"
                            @click="toggleSelectClientDialog('devedor')">mdi-magnify-plus</v-icon>
                        </template>
                        <template v-slot:selection="data">
                          {{ data.item.pessoa_nome }}
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title>{{ data.item.pessoa_nome }}</v-list-item-title>
                              <v-list-item-subtitle>{{ data.item.pessoa_cpf }} {{ data.item.pessoa_cnpj }}</v-list-item-subtitle>
                              <v-list-item-subtitle>{{ data.item.fones }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n3">
                    <v-col cols="12" class="px-2">
                      <v-autocomplete
                        v-model="contaPagarForm.obj_favorecido"
                        :loading="loading"
                        :search-input.sync="search_favorecido"
                        :items="array_favorecido"
                        class="mt-n2"
                        label="Favorecido *"
                        placeholder="Favorecido"
                        item-text="pessoa_nome" 
                        item-value="cod_pessoa"
                        auto-select-first  
                        filled 
                        outlined 
                        dense 
                        :rules="favorecidoRules"
                        required
                        return-object
                        background-color="map-deep-merge white" >
                        <template #append-outer>
                          <v-icon
                            class="pointer"
                            style="cursor: pointer"
                            color="grey darken-1"
                            @click="toggleSelectClientDialog('favorecido')">mdi-magnify-plus</v-icon>
                        </template>
                        <template v-slot:selection="data">
                          {{ data.item.pessoa_nome }}
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title>{{ data.item.pessoa_nome }}</v-list-item-title>
                              <v-list-item-subtitle>{{ data.item.pessoa_cpf }} {{ data.item.pessoa_cnpj }}</v-list-item-subtitle>
                              <v-list-item-subtitle>{{ data.item.fones }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                      </v-autocomplete>
                      <!-- <v-text-field
                        :value="contaPagarForm.nomeFavorecido"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Favorecido"
                        readonly
                        autofocus
                        label="Favorecido *">
                        <template #append-outer>
                          <v-icon
                            class="pointer"
                            style="cursor: pointer"
                            color="grey darken-1"
                            @click="toggleSelectClientDialog('favorecido')">mdi-magnify-plus</v-icon>
                        </template>
                      </v-text-field> -->
                    </v-col>
                  </v-row>

                  <!-- Empreendimento -->
                  <v-row class="mt-n4" v-if="habilita_empreendimento_aux == 'SIM' && !cod_imovel" >
                    <v-col :cols="array_fases.length > 0 ? '8' : '12'" class="px-2">
                      <v-autocomplete
                        v-model="contaPagarForm.cod_empreendimento"
                        clearable
                        label="Empreendimento"
                        filled
                        dense
                        @change="buscaFases()"
                        outlined
                        background-color="#FFF"
                        :items="empreendimentosList"
                        item-text="text"
                        item-key="id" />
                    </v-col>
                    <v-col cols="4" class="px-2" v-if="array_fases.length > 0">
                      <!-- <pre>{{ array_fases }}</pre> -->
                      <v-autocomplete
                        v-model="contaPagarForm.cod_empreend_fase"
                        clearable
                        label="Fases"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        return-object
                        :items="array_fases"
                        item-text="nome_fase"
                        item-key="cod_empreend_fase" />
                    </v-col>
                  </v-row>

                  <!-- Imovel Avulso -->
                  <v-row class="mt-n4" v-if="habilita_imovelavulso_aux == 'SIM' && !contaPagarForm.cod_empreendimento">
                    <v-col cols="12" class="px-2">

                      <v-autocomplete
                        :key="key_contaPagarAutocomplete"
                        v-model="contaPagarForm.cod_imovel"
                        :search-input.sync="searchImoveis"
                        :items="imoveis"
                        no-filter
                        hide-selected
                        clearable
                        label="Imóvel Avulso" 
                        placeholder="Imóvel Avulso" 
                        item-text="nome_imovel" 
                        item-value="cod_imovel"
                        auto-select-first  
                        filled 
                        outlined 
                        dense 
                        background-color="map-deep-merge white"                        
                      />

                      <!-- :readonly="cod_imovel ? true : false" -->
                    </v-col>
                  </v-row>

                  <v-row class="mt-n5 mb-1">
                    <v-col col="12" class="px-2">
                      <v-data-table 
                        class="rounded-lg tabela" 
                        flat 
                        :items="contaPagarForm.cob_item" 
                        :headers="itensTableHeaders" 
                        :loading="loading" 
                        :items-per-page="20" 
                        hide-default-header 
                        hide-default-footer 
                        loading-text="Carregando...  aguarde..." 
                        no-data-text="Nenhum Registro Encontrado" 
                        no-results-text="Nenhum Registro Encontrado">
                        <template v-slot:top>
                          <v-row class="d-flex align-center justify-between">
                            <v-col col="12">
                              <v-toolbar flat class="rounded-lg">
                                <p class="font-primary font-weight-bold body-1 text-center mt-1" :style="{ 'color':COR_SUBTITULO }">
                                  Itens *
                                </p>  

                                <v-spacer></v-spacer>

                                <v-btn
                                  @click="dialog_item_novo = true; selectedEditItem = {}; empreendcobritem_tp_cobr = 'Item'"
                                  class="btn pr-6"
                                  elevation="0">
                                  <v-icon
                                    class="btn-icon-plus mr-1"
                                    color="green accent-4">mdi-plus</v-icon>
                                  <span
                                    class="font-weight-medium text-none"
                                    :style="{ 'color':COR_SUBTITULO }">Novo</span>
                                </v-btn>
                              </v-toolbar>
                            </v-col>
                          </v-row>
                        </template>

                        <!-- Titulos da Grade Itens -->
                        <template v-slot:header v-if="!isMobile">
                            <thead>
                              <tr>
                                <th v-for="h in itensTableHeaders" :key="h.value" :style="h.style_valor" :class="h.class" >
                                  <span>{{h.text}}</span>
                                </th>
                              </tr>
                            </thead>
                        </template>

                        <!-- Dados da Grade Itens -->
                        <template #item="{ item }">
                          <tr style="padding-top: 10px" v-if="!isMobile">
                            <td style="max-width: 40px">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn color="grey" dark icon v-bind="attrs" v-on="on" class="ml-n4">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>

                                <v-list>
                                  <v-list-item @click="editar(item)">
                                    <!-- Botao Editar -->
                                    <v-list-item-title>
                                      <v-icon color="green" class="mr-2 icon-menu">mdi-pencil</v-icon>
                                        Editar
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item @click="dialog_excluir_item = true; itemExcluirSelecionado = item">
                                    <v-list-item-title>
                                      <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon> Excluir
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                            <td>
                              {{ item.empreendcobritem_tp_cobr}}
                            </td>
                            <td>
                              {{ item.empreendcobritem_descr}}
                            </td>
                            <td>
                              {{ item.centrocusto?.centrocusto_descricao  }}
                            </td>
                            <td>
                              {{ item.planoconta?.planoconta_descricao  }}
                            </td>
                            <td align="right" :class="getItemValueColor(item)">
                              {{ formatPrice(item.empreendcobritem_valor) }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row class="mt-n3">
                    <v-col :col="isEditColValues" class="px-2">
                      <DatePicker
                        v-model="contaPagarForm.empreendcobr_parc_dt"
                        label="Venc. Inicial *"
                        outlined
                        backgroundColor="#FFF"
                        :style="data_picker"
                        dense
                        placeholder="dia/mês/ano"
                        :rules="datavenctoRules" />
                    </v-col>
                    <v-col v-if="!isEditar" col="4" class="px-2">
                      <v-text-field
                        v-model="contaPagarForm.empreendcobr_parcela_final"
                        hide-details="auto"
                        filled
                        dense
                        outlined
                        :rules="quantParcelasRules"
                        background-color="#FFF"
                        type="number"
                        placeholder="Quant. Parcelas"
                        label="Quant. Parcelas *" />
                    </v-col>
                    <v-col :col="isEditColValues" class="px-2">
                      <VuetifyMoney
                        v-model="valorParcela"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        readonly
                        :clearable="false"
                        placeholder="Valor Parcela"
                        label="Valor Parcela"
                        reverse />
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5">
                    <v-col col="6" class="px-2">
                      <v-select v-model="contaPagarForm.empreendcobr_tipo_custo" label="Tipo Documento *" filled dense outlined background-color="#FFF" :items="documentOptions" item-value="value" item-text="text" item-key="id"/>
                    </v-col>
                    <v-col col="6" class="px-2">
                      <v-select
                        v-model="contaPagarForm.empreendcobr_modalidade"
                        label="Tipo Cobrança *"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        :items="paymentOptions"
                        item-text="value"
                        item-key="id" />
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5 mb-1">
                    <v-col cols="8" class="px-2">
                      <v-text-field
                        v-model="contaPagarForm.empreendcobr_descricao"
                        hide-details="auto"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Descrição do Documento"
                        label="Descrição do Documento *"
                        maxlength="200" />
                    </v-col>
                    <v-col cols="4" class="px-2">
                      <v-text-field
                        v-model="contaPagarForm.empreendcobr_nf"
                        hide-details="auto"
                        filled
                        dense
                        outlined
                        background-color="#FFF"
                        placeholder="Nota Fiscal"
                        label="Nota Fiscal"
                        maxlength="100" />
                    </v-col>
                  </v-row>
                  <v-row class="mt-n3 mb-1">
                    <!-- Documentos -->
                    <v-col v-if="isEditar" cols="1" class="px-2">
                      <v-btn
                        @click="documentos(contaPagarForm)"
                        class="mt-1"
                        elevation="0"
                        style="background-color: var(--COR_SECUNDARIA)!important;">
                        <v-icon
                          class="btn-icon-plus mr-1 mt-1"
                          color="green accent-4">mdi-badge-account</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="1" class="px-2">
                    </v-col>
                    <v-col cols="10" class="px-2">
                      <v-textarea
                        v-model="contaPagarForm.empreendcobr_obs"
                        counter="100"
                        class="mt-1"
                        rows="2"
                        background-color="#FFF"
                        label="Observação"
                        placeholder="Observação"
                        filled
                        dense
                        outlined
                        maxlength="100" />
                    </v-col>
                  </v-row>

                </fieldset>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn v-if="dados_editar.acao2 != 'VISUALIZAR'" @click="fechar()" class="mr-4 btn caption font-weight-medium" color="primary" text>
          Cancelar
        </v-btn>
        <v-btn v-if="dados_editar.acao2 == 'VISUALIZAR'" @click="fechar" class="mr-4 btn caption font-weight-medium" color="primary" text>
          FECHAR
        </v-btn>
        <v-btn
          v-show="dados_editar.acao2 != 'VISUALIZAR'"
          @click="validate"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4"
          :loading="isSaveButtonLoading"
          :disabled="!valid || isSaveButtonDisabled">
            Salvar<!-- {{ saveButtonLabel }} -->
        </v-btn>
      </v-footer>
    </v-card>
    
    <!-- DIALOGO DEVEDOR -------------------------------------------------------->
    <v-container v-if="dialogDevedor">      
      <v-dialog v-model="dialogDevedor" scrollable max-width="500px" max-height="600px">
        <v-card>
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Pessoa
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="dialogDevedor = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do DEVEDOR -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile class="mt-n2" :editar_="true" />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do DEVEDOR -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn @click="dialogDevedor = false" class="mr-4 btn text-none" color="primary" text>
              Cancelar
            </v-btn>
            <v-btn :disabled="!store_Pessoa.pessoa_selecionado" class="btn text-none" color="primary" @click="selectPessoa">
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO DEVEDOR ---------------------------------------------------->

    <!-- DIALOG EXCLUIR ITEM -->
    <v-dialog :value="dialog_excluir_item" persistent max-width="430" class="d-flex justify-center">
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center flex-wrap mt-3 mb-3">
        <v-card-title class="text-h6 justify-center" style="width: 100%">
          ATENÇÃO<v-icon x-large color="red">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-title class="text-h6" style="word-break: break-word">
          Esta operação é IRREVERSÍVEL, deseja continuar?
        </v-card-title>
        <v-card-actions class="d-flex justify-start">
          <v-btn class="btn white--text" color="error accent-4" @click="excluirItem">
            Excluir
          </v-btn>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir_item = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOGO ITENS -------------------------------------------------------->
    <v-container v-if="dialog_item_novo">
      <v-dialog
        persistent
        v-model="dialog_item_novo"
        transition="dialog-bottom-transition"
        max-width="600"
        min-width="520">
        <ContasPagarNovoItemModal
          v-if="dialog_item_novo"
          :dialog_item_novo.sync="dialog_item_novo"
          :selectedEditItem="selectedEditItem"
          :valorParcela="valorParcela"
          :codCobranca="contaPagarForm.cod_empreendcobranca"
          :empreendcobritem_tp_cobr="empreendcobritem_tp_cobr"
          @save-item="saveItem"
          @edit-item="editItem" />
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO ITENS ---------------------------------------------------->

    <!-- Dialog Documentos -->
    <v-container v-if="dialog_documentos">
      <v-dialog
        persistent
        v-model="dialog_documentos"
        transition="dialog-bottom-transition"
        width="80%">
        <v-card 
          :style="{ 'background-color':COR_SECUNDARIA }"
          class="container pa-0">
          <v-card-title 
            :style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between pa-2">
            <span class="text-white title-page body-1">Documentos</span>
            <v-btn
              @click="dialog_documentos = false"
              icon
              dark
              color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <GedDocumentos
          :dados_documentos="dados_documentos"
          :cod_empreendcobranca_aux="cod_empreendcobranca_aux"
          :paramsTipoDocumento="paramsTipoDocumento"
          :paramBusca="paramBusca"
          :statusDocumento="statusDocumento"
          @fecharModal="dialog_documentos = false" />
      </v-dialog>
    </v-container>

  </div>
</template>

<script>
import { API, API_CNAB } from "../../../services/API"
import store_ContasPagar from "./store_ContasPagar";
import store_Pessoa from "../../Pessoas/store_Pessoa";
import store_Empreendimento from "../../Empreendimentos/store_Empreendimento"
import store_site from "../../../store/store_site";
import store_usuario from "../../../store/store_usuario"
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import VuetifyMoney from "../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import store_Financeiro from "../store_Financeiro";
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  MASK_CPFCNPJ,
  MASK_TELEFONE,
} from "../../../services/constantes";
import {
  CpfCnpjIsValid,
  telefoneIsValid,
  formatDate,
  maskCpfCnpj,
  formatPrice
} from "../../../services/funcoes";
import moment, { now } from "moment";
import PessoaConsultaMobile from '../../Pessoas/PessoaConsultaMobile.vue'
import ContasPagarNovoItemModal from './ContasPagarNovoItemModal.vue'
import GedDocumentos from "../../Ged/GedDocumentos.vue"
import { nextTick } from 'vue';

export default {
  name: "ContasPagarNovoModal",

  props: ["dialog_novo", 
          "dialog_novo_editar", 
          "dados_editar", 
          "dialogOpen", 
          "devedor_referencia", 
          'dialog_lancamentos',
          "cedente_selecionado",
          "habilita_empreendimento",
          "habilita_imovelavulso",
          'cod_imovel'],

  components: {
    VuetifyMoney,
    DatePicker,
    PessoaConsultaMobile,
    ContasPagarNovoItemModal,
    GedDocumentos
  },

  data() {
    return {
      store_Pessoa            : store_Pessoa,
      store_Financeiro        : store_Financeiro,
      store_site              : store_site,
      store_usuario           : store_usuario,
      store_ContasPagar       : store_ContasPagar,
      dialog_excluir_item     : false,
      itemExcluirSelecionado  : false,
      dialogOpen2             : this.dialogOpen,
      dialog_item_novo        : false,
      dialogType              : '',
      isSaveButtonLoading     : false,
      array_fases             : [],
      search_devedor          : null,
      search_favorecido       : null,
      array_devedor           : null,
      array_favorecido        : null,
      searchImoveis           : null,
      imoveis                 : [],
      empreendcobritem_tp_cobr : 'Item',
      habilita_empreendimento_aux : null,
      habilita_imovelavulso_aux   : null,
      key_contaPagarAutocomplete  : 0,

      contaPagarForm          : {
                                  cod_empreendcobranca : '',
                                  cod_devedor_referencia: '',
                                  nomeDevedor: '',
                                  cod_pessoa: '',
                                  nomeFavorecido: '',
                                  cod_empreendimento: null,
                                  cod_imovel        : null,
                                  cod_empreend_fase : null,
                                  empreendcobr_tipo: 'P',
                                  empreendcobr_situacao: 'Aberto',
                                  empreendcobr_tipo_custo: 'VARIAVEL',
                                  empreendcobr_modalidade: 'BOLETO',
                                  empreendcobr_parc_dt: '',
                                  empreendcobr_parc_valor: 0,
                                  empreendcobr_descricao: '',
                                  empreendcobr_historico: '',
                                  empreendcobr_parcela_final: 1,
                                  empreendcobr_obs: '',
                                  cob_item: [],
                                },
      selectedEditItem: {
                          empreendcobritem_manual   : "S",
                          empreendcobritem_estornar : "S",
                          item_tipo                 : 'P',
                        },
      COR_PRINCIPAL             : COR_PRINCIPAL,
      COR_SECUNDARIA            : COR_SECUNDARIA,
      COR_SUBTITULO             : COR_SUBTITULO,
      MASK_CPFCNPJ              : MASK_CPFCNPJ,
      MASK_TELEFONE             : MASK_TELEFONE,
      formatPrice               : formatPrice,
      formatDate                : formatDate,
      maskCpfCnpj               : maskCpfCnpj,

      checkbox1                 : true,
      checkbox2                 : false,

      proposta_incluir          : null,
      dados_proposta            : [],
      valid                     : false,
      form                      : false,
      loading                   : false,
      dialogDevedor             : false,

      dados_documentos          : {},
      cod_empreendcobranca_aux  : null,
      dialog_documentos         : false,

      /* SELECT AJUSTE */
      opcoes_tipo_parcela: [
        { id: "D", text: "D", value: "DESPESA" },
        { id: "P", text: "P", value: "PARCELA MANUAL" },
      ],
      /* TEXTOS */
      datavenctoRules: [
        (value) =>
          (value && value?.length >= 10) ||
          "Obs. Data de Vencimento deve ter no mínimo 8 caracteres",
        (value) =>
          (value && value?.length <= 10) ||
          "Obs. Data de Vencimento deve ter menos do que 8 caracteres",
      ],

      observacaoRules: [
        (value) =>
          (value && value?.length < 100) ||
          "Observação deve ter menos do que 100 caracteres",
      ],

      /* NÚMEROS */
      valorRules: [
        (value) => !!value || "O Valor é obrigatório",
        (value) => /^\d+$/.test(value) || "O Valor não é válido",
        (value) =>
          (value && value?.length <= 10) || "O Valor deve ter menos do que 10 caracteres",
      ],
      data_picker: {
        "min-width": "150px !important"
      },

      favorecidoRules: [
        (value) => !!value || "O Favorecido é obrigatório"
      ],

      devedorRules: [
        (value) => !!value || "O Devedor é obrigatório"
      ],
      
      quantParcelasRules: [
        (value) => !!value || "A quantidade de parcelas é obrigatória",
        (value) =>
          (value && Number(value) > 0) ||
          "A quantidade deve ser pelo menos 1",
        (value) =>
          (value && Number(value) <= 999) ||
          "A quantidade deve ser no máximo 999",
      ],
    };
  },

  async created () {

    // Se passar habilita_empreendimento=NÃO no props então não mostra, senão mostra 
    if (!this.habilita_empreendimento)
      this.habilita_empreendimento_aux = 'SIM';

    // Se passar habilita_imovelavulso=NÃO no props então não mostra, senão mostra 
    if (!this.habilita_imovelavulso)
      this.habilita_imovelavulso_aux = 'SIM';

    // Dentro de conciliação bancária, são passados alguns parametros que preenhem alguns 
    //          dados para criar uma nova cobrança com os dados do extrato selecionado
    if(!!this.cedente_selecionado) {

      let {data} = await API.get(`pessoa`, { params: { cod_pessoa : this.cedente_selecionado.cod_pessoa, fields : 'cod_pessoa,pessoa_nome,pessoa_cpf,pessoa_cnpj' } });
     
      this.array_devedor = []
      this.array_devedor.push({...data.result.rows[0]})
      this.contaPagarForm.obj_devedor_referencia = {...data.result.rows[0]}

      this.contaPagarForm.cob_item = []
      this.contaPagarForm.cob_item.push({
        "cod_empreendcobranca"        : "",
        "empreendcobritem_descr"      : this.dialog_lancamentos.descricao?.substring(0, 200),
        "centrocusto"                 : "",
        "planoconta"                  : "",
        "empreendcobritem_valor"      : Math.abs(this.dialog_lancamentos.valor),
        "empreendcobritem_obs"        : this.dialog_lancamentos.descricao?.substring(0, 200),
        "item_cre_deb"                : this.dialog_lancamentos.debito_credito,
        "empreendcobritem_manual"     : "S",
        "empreendcobritem_estornar"   : "S",
        "item_tipo"                   : "P",
        "empreendcobritem_tp_cobr"    : "Item",
      })

      this.contaPagarForm.empreendcobr_parc_dt    = moment(this.dialog_lancamentos.data).format()
      this.contaPagarForm.empreendcobr_tipo_custo = 'VARIAVEL'
      this.contaPagarForm.empreendcobr_modalidade = "DÉBITO AUTOMÁTICO"
      this.contaPagarForm.empreendcobr_descricao  = this.dialog_lancamentos.descricao?.substring(0, 200)
    }

    if (this.dados_editar.acao === 'novo' && this.dados_editar.acao2 === 'duplicar') {
      this.contaPagarForm = { ...this.dados_editar }
      this.array_devedor = [
        {
        cod_pessoa  : this.contaPagarForm.cobr_dev.cod_pessoa,
        pessoa_nome : this.contaPagarForm.cobr_dev.pessoa_nome,
        fones       : this.contaPagarForm.cobr_dev.fones,
        pessoa_cpf  : this.contaPagarForm.cobr_dev.pessoa_cpf,
        pessoa_cnpj : this.contaPagarForm.cobr_dev.pessoa_cnpj
      }]
      this.contaPagarForm.obj_devedor_referencia =  { cod_pessoa  : this.contaPagarForm.cobr_dev.cod_pessoa,
                                                      pessoa_nome : this.contaPagarForm.cobr_dev.pessoa_nome,
                                                      fones       : this.contaPagarForm.cobr_dev.fones,
                                                      pessoa_cpf  : this.contaPagarForm.cobr_dev.pessoa_cpf,
                                                      pessoa_cnpj : this.contaPagarForm.cobr_dev.pessoa_cnpj
                                                    }

      this.array_favorecido = [
        {
        cod_pessoa  : this.contaPagarForm.cobr_pess.cod_pessoa,
        pessoa_nome : this.contaPagarForm.cobr_pess.pessoa_nome,
        fones       : this.contaPagarForm.cobr_pess.fones,
        pessoa_cpf  : this.contaPagarForm.cobr_pess.pessoa_cpf,
        pessoa_cnpj : this.contaPagarForm.cobr_pess.pessoa_cnpj
      }]
      this.contaPagarForm.obj_favorecido =  { ...this.contaPagarForm.cobr_pess }

      this.contaPagarForm.empreendcobr_parcela_inicial           = 1
      this.contaPagarForm.empreendcobr_parcela_final             = 1
      delete this.contaPagarForm.cod_empreendcobranca

      await this.buscaFases()

      let lo_fase = this.array_fases.find(val => val.cod_empreend_fase === this.contaPagarForm.cod_empreend_fase)
      this.contaPagarForm.cod_empreend_fase = lo_fase
    }

    if (this.dados_editar.acao === 'editar') {
      this.contaPagarForm = { ...this.dados_editar }
      this.array_devedor = [
        {
        cod_pessoa  : this.contaPagarForm.cobr_dev.cod_pessoa,
        pessoa_nome : this.contaPagarForm.cobr_dev.pessoa_nome,
        fones       : this.contaPagarForm.cobr_dev.fones,
        pessoa_cpf  : this.contaPagarForm.cobr_dev.pessoa_cpf,
        pessoa_cnpj : this.contaPagarForm.cobr_dev.pessoa_cnpj
      }]
      this.contaPagarForm.obj_devedor_referencia =  { cod_pessoa  : this.contaPagarForm.cobr_dev.cod_pessoa,
                                                      pessoa_nome : this.contaPagarForm.cobr_dev.pessoa_nome,
                                                      fones       : this.contaPagarForm.cobr_dev.fones,
                                                      pessoa_cpf  : this.contaPagarForm.cobr_dev.pessoa_cpf,
                                                      pessoa_cnpj : this.contaPagarForm.cobr_dev.pessoa_cnpj
                                                    }

      this.array_favorecido = [
        {
        cod_pessoa  : this.contaPagarForm.cobr_pess.cod_pessoa,
        pessoa_nome : this.contaPagarForm.cobr_pess.pessoa_nome,
        fones       : this.contaPagarForm.cobr_pess.fones,
        pessoa_cpf  : this.contaPagarForm.cobr_pess.pessoa_cpf,
        pessoa_cnpj : this.contaPagarForm.cobr_pess.pessoa_cnpj
      }]
      this.contaPagarForm.obj_favorecido =  { ...this.contaPagarForm.cobr_pess }

      //console.log("🚀 ~ file: ContasPagarNovoModal.vue:878 ~ created ~ this.contaPagarForm:", this.contaPagarForm)

      await this.buscaFases()

      let lo_fase = this.array_fases.find(val => val.cod_empreend_fase === this.contaPagarForm.cod_empreend_fase)
      this.contaPagarForm.cod_empreend_fase = lo_fase

    }
    else{
      //this.array_devedor = [
      //  {
      //  cod_pessoa : store_usuario.user.empresa_cod_pessoa,
      //  pessoa_nome : store_usuario.user.empresa_nome
      //}]
      //this.contaPagarForm.obj_devedor_referencia =  { cod_pessoa : store_usuario.user.empresa_cod_pessoa, pessoa_nome : store_usuario.user.empresa_nome}
      //this.contaPagarForm.cod_devedor_referencia  = store_usuario.user.empresa_cod_pessoa || this.devedor_referencia.cod_pessoa
      //this.contaPagarForm.nomeDevedor             = store_usuario.user.empresa_nome || this.devedor_referencia.nome_pessoa
    }

    // Abrindo Empreendimentos
    store_Empreendimento.EmpreendimentoGet()

      // Pegando dadod do Imovel, caso tenha passado imovel nos parâmetros
      if (this.cod_imovel || this.contaPagarForm.cod_imovel) {
          let cod_Imo = this.cod_imovel ? this.cod_imovel : this.contaPagarForm.cod_imovel;
          console.log('___________', cod_Imo)
          let p_params = { params: {cod_imovel: cod_Imo } }
          const ls_Rel_Imoveis = await API.get(`imovel`, p_params);
          this.imoveis = ls_Rel_Imoveis.data.result.rows;
          this.contaPagarForm.cod_imovel = cod_Imo;
          this.cod_imovel                 = cod_Imo;
          this.key_contaPagarAutocomplete++;
      }

  },

  mounted() {
  },

  computed: {
    isEditColValues () {
      return this.isEditar ? '6' : '4'
    },

    valorParcela () {
      if (this.contaPagarForm.cob_item.length) {
        return this.contaPagarForm.cob_item.reduce((curr, prev) => { 
          if (prev.item_cre_deb === 'D') {
            return curr += +prev.empreendcobritem_valor
          }

          return curr -= +prev.empreendcobritem_valor
        }, 0)
      }

      return 0
    },

    isEditar () {
      return this.dados_editar.acao === 'editar'
    },

    isSaveButtonDisabled () {
      const requiredFields = [
        'empreendcobr_descricao', 'devedor', 'favorecido', 'cob_item', 'empreendcobr_parcela_final',
        'empreendcobr_modalidade', 'empreendcobr_tipo_custo', 'empreendcobr_parc_dt'
      ]
      
      let hasEmpty = false

      Object.entries(this.contaPagarForm).forEach(([key, value]) => {
        if (key === 'empreendcobr_parc_valor' && !+this.valorParcela) hasEmpty = true 

        if (requiredFields.includes(key) && !value) hasEmpty = true
      })

      return hasEmpty
    },

    saveButtonLabel () {
      return this.isEditar ? 'Editar' : 'Salvar'
    },

    empreendimentosList () {
      return store_Empreendimento.dados?.map(({ cod_empreendimento, empreend_nome }) => ({
        id: cod_empreendimento,
        value: cod_empreendimento,
        text: empreend_nome
      }))
    },

    itensTableHeaders () {
      return [
        { text: '', value: '' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Centro de Custo', value: 'centroDeCusto' },
        { text: 'Plano de Contas', value: 'planoDeConta' },
        { text: 'Valor', value: 'valor', style_valor: 'text-align: right' }
      ]
    },

    documentOptions () {
      return [
        { id: 'F', value: 'FIXO', text: 'FIXO' },
        { id: 'V', value: 'VARIAVEL', text: 'VARIAVEL' }
      ]
    },

    paymentOptions () {
      return [
        { id: 'boleto', value: 'BOLETO', text: 'boleto' },
        { id: 'carne', value: 'CARNÊ', text: 'carne' },
        { id: 'cobrancaDireta', value: 'COBRANÇA DIRETA (Balcão, pessoalmente, etc)', text: 'cobrancaDireta' },
        { id: 'debito_automatico', value: 'DÉBITO AUTOMÁTICO', text: 'debito_automatico' },
        { id: 'deposito', value: 'DEPÓSITO', text: 'deposito' },
        { id: 'guia', value: 'GUIA', text: 'guia' },
        { id: 'promissoria', value: 'NOTA PROMISSÓRIA', text: 'promissoria' },
        { id: 'nf', value: 'NOTA FISCAL', text: 'nf' },
        { id: 'pix', value: 'PIX', text: 'pix' },
      ]
    },

    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    }
  },

  updated(){

    if(!!this.cedente_selecionado && store_Empreendimento?.dados.length > 0){
      store_Empreendimento.dados.find(val =>  {        
        if (val.cod_pessoa_conta == this.dialog_lancamentos.cod_pessoa_conta) {
          this.contaPagarForm.cod_empreendimento = val.cod_empreendimento;
        }
      });
    }

  },

  watch: {
    // 'store_Empreendimento.dados'(val) {
    //   console.log('store_Empreendimento', val);
    // },

    search_devedor(query) {
      if (query && (!this.contaPagarForm?.obj_devedor_referencia || this.contaPagarForm?.obj_devedor_referencia?.pessoa_nome !== query)) {
        //console.log('zzzzzzz', this.contaPagarForm?.obj_devedor_referencia?.pessoa_nome, query)
        this.querySearch(query, 'devedor');
      }
    },
    search_favorecido(query) {
      if (query && (!this.contaPagarForm?.obj_favorecido || this.contaPagarForm?.obj_favorecido?.pessoa_nome !== query)) {
        this.querySearch(query, 'favorecido');
      }
    },
    searchImoveis(query) {
      if (query && (!this.selectImovel || this.selectImovel.text !== query)) {
        //console.log('qqq', query, this.selectImovel);
        this.querySearch(query, 'imovel avulso');
        this.selectImovel = query;
      }
    },
    dialogOpen2: {
      handler(newVal, oldVal) {
      },
      deep: true,
    },

    dados_editar (newValue) {
      this.contaPagarForm = { ...newValue }
    }
  },

  methods: {
    async findParaPegarEmpreendimento(){
      let result = await store_Empreendimento.dados.find(val => val.cod_pessoa_conta == this.dialog_lancamentos.cod_pessoa_conta)
      //console.log("🚀 ~ file: ContasPagarNovoModal.vue:856 ~ findParaPegarEmpreendimento ~ result:", result)
      return result
    },

    documentos(item) {
      this.dados_documentos = {...item}
      this.dados_documentos.tipo = 'CONTAS A PAGAR';
      this.paramsTipoDocumento = {tipo_cadastro: 'cobranca', tipo_cadastro_descricao: "Documentos Pagar/Receber"}
      this.paramBusca = {cod_empreendcobranca : item.cod_empreendcobranca}
      this.statusDocumento = 'Aprovado'
      this.cod_empreendcobranca_aux = item.cod_empreendcobranca
      this.dialog_documentos = true
    },

    busca_timeout(val, tipo) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {

        // Consulta Pessoas -------------------------------------------
        if(tipo === 'devedor' || tipo === 'favorecido') {
          const ls_Rel_Devedor = await API.get(`pessoa`, val);
          if (Array.isArray(ls_Rel_Devedor.data?.result?.rows)) {
            if(tipo === 'devedor') {
              this.array_devedor = ls_Rel_Devedor.data?.result?.rows
            } else if(tipo === 'favorecido') {
              this.array_favorecido = ls_Rel_Devedor.data?.result?.rows || [];
            }
          }
        }
        else if(tipo === 'imovel avulso') {
          //let p_params = { params: {filtro: val, limit: 999999999 } }
          const ls_Rel_Imoveis = await API.get(`imovel`, val);
          //console.log("this.imoveis dentro do search", val, ls_Rel_Imoveis)
          this.imoveis = ls_Rel_Imoveis.data.result.rows;
        }
      }, 600);
    },

    querySearch(query, tipo) {
      var ls_fields = null
      if(tipo === 'devedor') {
        ls_fields = 'cod_pessoa,pessoa_nome,pessoa_cpf,pessoa_cnpj'
      }else if(tipo === 'favorecido') {
        ls_fields = 'cod_pessoa,pessoa_nome,pessoa_cpf,pessoa_cnpj'
      }else if(tipo === 'imovel avulso') {
        ls_fields = 'cod_imovel,nome_imovel'
      }
      let p_params = { params: { filtro: query, fields: ls_fields } }
      this.loading = true
      this.busca_timeout(p_params, tipo);
      this.loading = false
    },

    async buscaFases(){
      if(this.contaPagarForm.cod_empreendimento){
        let {data} = await API.get(`fase_empreendimento`, { params: { cod_empreendimento : this.contaPagarForm.cod_empreendimento } });
        this.array_fases = data.result
      }
    },

    editar(item) {
      //console.log("🚀 ~ file: ContasPagarNovoModal.vue:666 ~ editar ~ item:", item)
      let indice = this.contaPagarForm.cob_item.indexOf(item)
      item.indice = indice
      this.selectedEditItem = { ...item }
      this.dialog_item_novo = true
    },

    async excluirItem () {
      const index = this.contaPagarForm.cob_item.indexOf(this.itemExcluirSelecionado )
     
      this.contaPagarForm.cob_item.splice(index, 1)
      this.dialog_excluir_item = false
    },

    getItemValueColor (item) {
      return item.item_cre_deb === 'C' ? 'green--text' : 'red--text'
    },

    toggleSelectClientDialog (type) {
      this.dialogType = type
      this.dialogDevedor = !this.dialogDevedor
    },

    selectPessoa () {
      if (!!this.store_Pessoa.pessoa_selecionado) {
        if (this.dialogType === 'devedor') {
          this.contaPagarForm.cod_devedor_referencia = this.store_Pessoa.pessoa_selecionado.cod_pessoa
          this.contaPagarForm.nomeDevedor = this.store_Pessoa.pessoa_selecionado.pessoa_nome
          this.array_devedor = []
          this.array_devedor.push(this.store_Pessoa.pessoa_selecionado)
          this.contaPagarForm.obj_devedor_referencia = {...this.store_Pessoa.pessoa_selecionado}
        } else {
          this.contaPagarForm.cod_pessoa = this.store_Pessoa.pessoa_selecionado.cod_pessoa
          // this.contaPagarForm.nomeFavorecido = this.store_Pessoa.pessoa_selecionado.pessoa_nome
          // this.contaPagarForm.cpfFavorecido = this.store_Pessoa.pessoa_selecionado.pessoa_cpf
          // this.contaPagarForm.cnpjFavorecido = this.store_Pessoa.pessoa_selecionado.pessoa_cnpj
          // this.contaPagarForm.celular1Favorecido = this.store_Pessoa.pessoa_selecionado.fone_celular1
          // this.contaPagarForm.celular2Favorecido = this.store_Pessoa.pessoa_selecionado.fone_celular2
          this.array_favorecido = []
          this.array_favorecido.push(this.store_Pessoa.pessoa_selecionado)
          this.contaPagarForm.obj_favorecido = {...this.store_Pessoa.pessoa_selecionado}
        }
  
        this.toggleSelectClientDialog()
      }
    },

    fechar() {
      this.proposta_incluir = null;
      this.dados_proposta = [];
      this.$emit("update:dialog_novo", false);
      this.resetForm()
    },

    resetForm () {
      this.contaPagarForm = {
        cod_empreendcobranca: '',
        cod_devedor_referencia: '',
        cod_pessoa: '',
        cod_empreendimento: null,
        cod_imovel: null,
        empreendcobr_tipo: 'P',
        empreendcobr_situacao: 'Aberto',
        empreendcobr_tipo_custo: '',
        empreendcobr_modalidade: '',
        empreendcobr_parc_dt: '',
        empreendcobr_parc_valor: 0,
        empreendcobr_descricao: '',
        empreendcobr_historico: '',
        empreendcobr_parcela_inicial: '',
        empreendcobr_parcela_final: '',
        empreendcobr_obs: '',
        cob_item: [],
      }
    },

    async buscaProposta(item) {
      const ls_Res = await this.store_ContasPagar.ContasPagarBuscaProposta(item);
      this.dados_proposta = ls_Res.data.result[0];
    },

    reset() {
      this.$refs.form.reset();
    },

    async validate() {
      let lo_Conta = {
        nomeDevedor    : this.contaPagarForm.obj_devedor_referencia.pessoa_nome,
        nomeFavorecido : this.contaPagarForm.obj_favorecido.pessoa_nome,
        cpfFavorecido  : this.contaPagarForm.obj_favorecido.pessoa_cpf,
        cnpjFavorecido : this.contaPagarForm.obj_favorecido.pessoa_cnpj,
        celular1Favorecido : this.contaPagarForm.obj_favorecido.fone_celular1,
        celular2Favorecido : this.contaPagarForm.obj_favorecido.fone_celular2,
        cobr_pess                       : { ...this.contaPagarForm.obj_favorecido },
        cobr_dev                        : { ...this.contaPagarForm.obj_devedor_referencia },

        emp_cobr                        : { empreend_nome : this.contaPagarForm?.emp_cobr?.empreend_nome },
        cod_empreendcobranca            : this.contaPagarForm.cod_empreendcobranca || '',
        cod_devedor_referencia          : this.contaPagarForm.obj_devedor_referencia.cod_pessoa,
        cod_devedor_pagador             : this.contaPagarForm.obj_devedor_referencia.cod_pessoa,
        cod_pessoa                      : this.contaPagarForm.obj_favorecido.cod_pessoa,
        cod_empreendimento              : this.contaPagarForm.cod_empreendimento,
        cod_imovel                      : this.contaPagarForm.cod_imovel,
        empreendcobr_tipo               : 'P',
        empreendcobr_situacao           : 'Aberto',
        empreendcobr_tipo_custo         : this.contaPagarForm.empreendcobr_tipo_custo,
        empreendcobr_modalidade         : this.contaPagarForm.empreendcobr_modalidade,
        empreendcobr_parc_dt            : this.contaPagarForm.empreendcobr_parc_dt,
        empreendcobr_parc_valor         : this.valorParcela,
        empreendcobr_parc_valor_final   : this.valorParcela,
        empreendcobr_descricao          : this.contaPagarForm.empreendcobr_descricao,
        empreendcobr_historico          : '',
        empreendcobr_parcela_inicial    : this.contaPagarForm.empreendcobr_parcela_inicial,
        empreendcobr_parcela_final      : this.contaPagarForm.empreendcobr_parcela_final,
        empreendcobr_obs                : this.contaPagarForm.empreendcobr_obs,
        cob_item                        : [ ...this.contaPagarForm.cob_item ],
        cod_empreend_fase               : this.contaPagarForm?.cod_empreend_fase?.cod_empreend_fase,
        pagador                         : this.contaPagarForm.obj_favorecido.pessoa_nome,
        empreendcobr_parc_acres_valor   : null,
        empreendcobr_parc_desc_valor    : null,
        empreendcobr_pagto_valor_multa  : null,
        empreendcobr_pagto_valor_juros  : null,
        empreendcobr_nf                 : this.contaPagarForm.empreendcobr_nf,
        cobr_pag                        : { ...this.contaPagarForm.obj_devedor_referencia }
      }

      if(lo_Conta.cod_pessoa === lo_Conta.cod_devedor_referencia){
      this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'O Favorecido não pode ser o mesmo que o Devedor!'
        this.store_site.alert           = true;
        return
      }
     
      // console.log('conta', lo_Conta);
      // return
      if (this.isEditar)
        this.editarConta(lo_Conta)
      else
        this.criarNovaConta(lo_Conta)
    },

    // Cadastrando novo lançamento
    async criarNovaConta(conta) {
      this.isSaveButtonLoading = true;
      const response = await store_ContasPagar.ContasPagarNovo(conta)
      if (response.data.result.status == 200) {
        conta.cod_empreendcobranca = response.data.result.data[0].cod_empreendcobranca
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = response.data.result.result.trim();
        this.store_site.alert           = true;
        this.$emit('criarNovaConta', conta)
      } else {
        // Mensagem de exclusão não realizada
        this.isSaveButtonLoading = false;
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = response.data.result.trim();
        this.store_site.alert           = true;
      }
      this.isSaveButtonLoading = false;
    },

    // Editando Lançamento existens
    async editarConta(conta) {
      this.isSaveButtonLoading = true;
      const response = await store_ContasPagar.ContasPagarEditar(conta)
      if (response.data.result.status == 200) {
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = response.data.result.result.trim();
        this.store_site.alert           = true;
        this.$emit('editarConta', conta)
      } else {
        // Mensagem de exclusão não realizada
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = response.data.result.result.trim();
        this.store_site.alert           = true;
      }
      this.isSaveButtonLoading = false;
    },

    editItem(item) {
      //console.log("🚀 saveItem", this.contaPagarForm.cob_item)

      // console.log("🚀 editItem", item)
      this.$set(this.contaPagarForm.cob_item, item.indice, { ...item })
      this.contaPagarForm.empreendcobr_descricao = this.contaPagarForm.cob_item[0].empreendcobritem_descr
      this.selectedEditItem = {}
    },

    saveItem (item) {
      // this.contaPagarForm.empreendcobritem_descr = this.contaPagarForm.cob_item[0].empreendcobritem_descr
      this.contaPagarForm.cob_item.push({ ...item })
      this.contaPagarForm.empreendcobr_descricao = this.contaPagarForm.cob_item[0].empreendcobritem_descr
      this.$emit("atualizaItem", { ...this.contaPagarForm.cob_item })
    }
  },

};
</script>

<style scoped>
.container {
  background: #f2f6f7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
  font-size: 19px !important;
  color: #ffffff;
  letter-spacing: 0px;
}

.title-page-menor {
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  color: #ffffff;
  letter-spacing: 0px;
}

.wrapper {
  /* height: 340px; */
  height: 660px;
  overflow-y: auto;
}

.wrapper::-webkit-scrollbar{
  display:none;
}

.row-value {
  margin-top: -146px;
}

.btn {
  width: 100px;
}

.v-divider {
  background: var(--COR_PRINCIPAL) !important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}
</style>

<style scoped lang="scss">
.input-text {
  ::v-deep {
    .v-text-field {
      input {
        font-size: 19px;
      }
    }
  }
}
</style>
